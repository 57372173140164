<template>
    <v-container color="blue-grey lighten-5">
        <confirm ref="confirm" />
        <QtyEditDialog ref="qtyedit" />
        <v-row class="text-center" v-if="isLoading"><p>Trwa ładowanie</p></v-row>
        <v-row class="text-center" v-else>
            <v-col cols="12">
                <v-alert
                    dense
                    border="left"
                    outlined
                    type="warning"
                    v-for="w in warnings" :key="w._id"
                    style="cursor:pointer;"
                    @click="showModalForWarring(w.orderPos, w.variant, w.code)"
                >
                    <span v-if="w.code == 'missing.details'"> Brak podanych szczegółów produktu w: </span>
                    <span v-else-if="w.code == 'missing.content'">Brak podanych treści w produkcie: </span>
                    <span v-else-if="w.code == 'missing.guest-list'">
                        Brak podanej listy gości w produkcie: 
                    </span>
                    <strong class="mr-1" >{{w.orderPos.name}}</strong>
                    <span class="text-caption" v-if="(w.code == 'missing.content' || w.code == 'missing.guest-list') && w.variant">
                        (Wariant: {{w.variant.name}})
                    </span>

                    <span class="text-caption" v-if="w.code == 'missing.details' && w.orderPos.details">
                        ( Brakuje:
                        <span v-for="(d, key, index) in w.orderPos.details" :key=d>
                            {{getFeatureName(key)}}
                            <span v-if="index != Object.keys(w.orderPos.details).length - 1">,</span>
                        </span>)
                    </span>

                    <v-btn class="ml-2" text color="teal accent-4" x-small @click="showModalForWarring(w.orderPos, w.variant, w.code)">(Dodaj)</v-btn>
                </v-alert>
                <v-alert v-if="!order.delivery.data || Object.keys(order.delivery.data).length < 2" dense outlined border="left" type="error" >
                    Brak podanego <strong style="cursor:pointer;">adresu dostawy <span v-if="!order.delivery.typeId">i przewoźnika</span></strong>
                    <v-btn class="ml-2" text color="teal accent-4" x-small @click.prevent="editDelivery">(Dodaj)</v-btn>
                </v-alert>
            </v-col>
            <v-col cols="12" md="12" sm="12">
                <v-data-table
                    :headers="headers"
                    :items="products"
                    :hide-default-footer="false"
                    :items-per-page="20"
                    sort-by="product"
                    class="elevation-1"
                    group-by="category"
                >
                    <template v-slot:top>
                        <v-toolbar flat color="white" dense>
                            <v-toolbar-title class="black--text">Zamówienie nr #{{ order.code.toUpperCase() }}</v-toolbar-title><br/>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" :disabled="editBlock" small @click="addProduct">Dodaj produkt</v-btn>
                                
                        </v-toolbar>
                        <v-row class="ml-3">
                            <v-col cols="12" md="3">
                                <p class="text-left text-caption mb-1">
                                    Status zamówienia:<br/>
                                    <OrderStatusChip :status="order.status"  />
                                </p>
                                <a style="float:left;" class="text-caption mb-1" @click="showLogs">Zobacz historię zamówienia
                                    <v-icon color="purple" x-small>mdi-history</v-icon>
                                </a>
                            </v-col>
                            <v-col cols="12" md="3">
                                
                                <p class="text-left text-caption" v-if="order.designer">
                                    Grafik przydzielony do Państwa zamówienia:<br/>
                                    <strong>{{order.designer}}</strong>
                                    <!-- <a href="#">(napisz wiadomość)</a> -->
                                </p>
                            </v-col>
                           
                        </v-row>
                        <v-divider
                                
                                
                        ></v-divider>
                    </template>
                    <template v-slot:group.header="{ group, headers, items, isMobile }">
                        <td v-if="group" class="text-start" :class="{'v-data-table__mobile-row': isMobile}">
                            <strong>{{ items[0].categoryTitle || "Bez grupy" }}</strong>
                            <span v-if="items[0].paidDetails">
                                <br/>{{ items[0].paidDetails }}
                                <v-btn icon x-small title="Zmień wariant produktu" @click="editPaidDetails(items[0].orderPos)">
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn> 
                            </span>
                        </td>
                        <td v-if="group" :class="{'v-data-table__mobile-row': isMobile}" class="text-start" :colspan="headers.length-3">
                            <v-btn
                                class="ml-2"
                                outlined
                                color="purple lighten-2"
                                x-small
                                @click="addPersonalCartPosition(items[0].orderPos._id)">
                                Dodaj ten produkt z inną treścią lub wersją
                            </v-btn>
                        </td>
                        <td v-if="group" :class="{'v-data-table__mobile-row': isMobile}" >
                            <div v-if="items[0].neededDetailsCount > 0">
                                <span class="text-caption font-weight-light">Szczegóły produktu {{ items[0].providedDetailsCount }} / {{ items[0].neededDetailsCount }}</span>
                                <v-icon v-if="false == editBlock" small class="ml-2" @click="gotoDetails(items[0].orderPos._id)">mdi-pencil</v-icon>
                            </div>
                        </td>
                        <td v-if="group" :class="{'v-data-table__mobile-row': isMobile}" >
                            <div v-if="false == editBlock">
                                <v-btn
                                    v-if="isMobile"
                                    class=" ml-2"
                                    outlined
                                    color="purple lighten-2"
                                    x-small
                                    :disabled="editBlock"
                                    @click="deleteCartPos(items[0].orderPos._id)"
                                >Usuń produkt</v-btn>
                                <v-icon v-else small @click="deleteCartPos(items[0].orderPos._id)">mdi-delete</v-icon>

                            </div>
                        </td>
                    </template>
                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="!item.variant && item.neededDetailsCount > 0">
                            <span class="text-caption font-weight-light">Szczegóły produktu {{ item.providedDetailsCount }} / {{ item.neededDetailsCount }}</span>
                            <v-icon v-if="false == editBlock" small class="ml-2" @click="gotoDetails(item.orderPos._id)">mdi-pencil</v-icon>
                        </div>
                        <v-icon v-if="false == editBlock && !item.isPermanent" small @click="deleteCartPosVariant(item.orderPos._id, item.variant)">mdi-delete</v-icon>
                    </template>
                    <template v-slot:item.qty="{ item }">
                        <span v-if="item.isPermanent">&nbsp;</span>
                        <v-btn v-else-if="false == editBlock && (item.variant.needGuestList && !item.variant.guestList || !item.variant.needGuestList)" text small style="cursor:pointer" @click="editVariantQty(item.orderPos, item.variant)" :disabled="editBlock">
                            <v-chip color="purple lighten-2" class="white--text" small style="cursor:pointer">{{ item.qty }}</v-chip>
                            <span class="text-caption grey--text ml-1">(edytuj)</span>
                        </v-btn>


                        <v-tooltip bottom v-else color="primary">
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn text small v-bind="attrs" v-on="on" >
                                    <v-chip color="purple lighten-2" class="white--text" small style="cursor:pointer">{{ item.qty }}</v-chip>
                                </v-btn>
                            </template>
                            <span>Zmień listę gości, a system sam przeliczy potrzebną ilość</span>
                        </v-tooltip>
                    </template>
                    

                    <template v-slot:item.projectLink="{ item }">
                        <span v-if="item.variant.projectLink" style="cursor:pointer;">
                            <v-chip  small outlined  color="purple lighten-2">

                                <a target="_blank" :href="item.variant.projectLink">
                                    link
                                    <v-icon color="purple lighten-2" dark right > mdi-download-outline </v-icon>
                                </a>
                            </v-chip> 
                            <!-- <span class="text-caption grey--text ml-1" v-if="false == editBlock" @click="changeProject(item.orderPos._id, item.variant)">(zmień)</span> -->
                        </span>

                        <span v-else>
                            <v-icon color="grey"  >
                                mdi-drag-horizontal-variant
                            </v-icon>
                        </span>
                    </template>
                    <template v-slot:item.projectStatus="{ item }">
                        <span v-if="item.variant.projectStatus">
                            <v-chip
                                :color=stPMap[item.variant.projectStatus].color
                                class="white--text"
                                small
                            >{{ stPMap[item.variant.projectStatus].name}}</v-chip>
                        </span>
                        <span v-else>
                            <v-icon color="grey"  >
                                mdi-drag-horizontal-variant
                            </v-icon>
                        </span>
                    </template>

                    <template v-slot:item.texts="{ item }">
                        <v-icon v-if="!item.texts" color="grey"  >
                            mdi-drag-horizontal-variant
                        </v-icon>
                        <span v-else @click="editTextsInVariant(item.orderPos, item.variant)" style="cursor:pointer" :disabled="editBlock">
                            <span v-if="editBlock">
                                <v-icon
                                    :color="item.texts == 'ok' ? 'teal accent-4' : 'red lighten-2'"
                                    dark
                                >
                                    {{ item.texts == 'ok' ? 'mdi-check' : 'mdi-alert-box-outline'}}
                                </v-icon>
                            </span>
                            <span v-else>
                                <span v-if="item.texts == 'ok'" class="text-caption teal--text">(Edytuj treść)</span>
                                <span v-else class="text-caption red--text">(Dodaj treść)</span>
                            </span>
                        </span>

                    </template>
                    <template v-slot:item.guestsList="{ item }">
                        <v-icon v-if="!item.guestsList" color="grey"  >
                            mdi-drag-horizontal-variant
                        </v-icon>
                        <span v-else @click="editGuestListInVariant(item.orderPos, item.variant)" style="cursor:pointer" :disabled="editBlock">
                            <span v-if="editBlock">
                                <v-icon
                                    :color="item.guestsList == 'ok' ? 'teal accent-4' : 'red lighten-2'"
                                    dark
                                >
                                    {{ item.guestsList == 'ok' ? 'mdi-check' : 'mdi-alert-box-outline'}}
                                </v-icon>
                            </span>
                            <span v-else>
                                <span v-if="item.guestsList == 'ok'" class="text-caption teal--text">(Edytuj listę gości)</span>
                                <span v-else class="text-caption red--text">(Dodaj listę gości)</span>
                            </span>
                        </span>
                       

                    </template>
                    <template v-slot:no-data>
                        Brak produktów w Twoim koszyku
                    </template>
                </v-data-table>
            </v-col>
            <v-col cols="12"  md="4" xs="12" class="text-left d-flex flex-column">
                <v-card class="flex">
                    <v-card-text>
                        <div class="black--text mb-4 font-weight-medium">Aktualna wartość Twojego zamówienia</div>
                        <v-simple-table dense >
                            <template v-slot:default>
                                <thead>
                                </thead>
                                <tbody class="">
                                    <tr>
                                        <td class="text-left">Produkty:</td>
                                        <td class="text-left">{{ order.prices.products | money }}</td>
                                    </tr>
                                    <tr v-if="order.prices.delivery">
                                        <td>Wysyłka:</td>
                                        <td>
                                            <span v-if="order.prices.delivery">{{order.prices.delivery | money}}</span>
                                        </td>
                                    </tr>
                                    <tr v-if="order.vouchers.length > 0">
                                        <td>Voucher:</td>
                                        <td>
                                            <span class="green--text" style="display:block;"  v-for="(voucher, key) in order.vouchers" :key=key>
                                                <span v-if="voucher.type == 'percent'">
                                                    -{{voucher.value*100}}%
                                                </span>  
                                                <span v-else>
                                                    -{{voucher.value | money}}
                                                </span>
                                                <span class="text-caption grey--text">({{voucher.name}})</span>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr v-if="order.isExpress">
                                        <td class="primary--text">Usługa express:</td>
                                        <td class="primary--text"> {{ order.prices.express | money }}</td>
                                    </tr>
                                    <tr>
                                        <td>Razem:</td>
                                        <td>
                                            <div class="font-weight-medium text--subtitle-1"> 
                                                <span v-if="order.vouchers.length == 0">
                                                    {{ totalToPay | money }}
                                                </span>
                                                <span v-else>
                                                    {{ totalToPay | money }}
                                                    <span class="text-decoration-line-through text-caption grey--text ml-1">
                                                        ({{ (order.prices.products + order.prices.delivery) | money}})
                                                    </span>
                                                </span> 
                                            </div>
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="border-top: 1px solid">Zapłacono:</td>
                                        <td style="border-top: 1px solid">{{totalPaid | money}}</td>
                                    </tr>
                                    <tr v-if="totalPendingPayments">
                                        <td class="grey--text">Oczekujące:</td>
                                        <td class="grey--text">{{totalPendingPayments | money}}</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <span v-if="leftToPay > 0"> Niedopłata: </span>
                                            <span v-else> Nadpłata: </span>
                                        </td>
                                        <td v-bind:class="{ 'red--text': leftToPay > 0, 'font-weight-medium text--subtitle-1': leftToPay < 0 }">{{ Math.abs(leftToPay) | money }}</td>
                                    </tr>
                                </tbody>
                            </template>
                        </v-simple-table>
                        <div v-if="!order.invoice || !order.invoice.nip" class="mx-1 my-4"><a href="#" @click.prevent="showInvoiceDialog">(Chcę otrzymać fakturę VAT)</a></div>
                        <div v-if="order.invoice && order.invoice.nip" class="black--text mb-2 font-weight-medium mt-5 mx-1">Faktura</div>    
                        <v-row v-if="order.invoice && order.invoice.nip">
                            <v-col cols="12" md="5" sm="5">
                                <span class="grey--text">Dane do faktury:</span> 
                            </v-col>
                            <v-col cols="12" md="7" sm="7" class="black--text">
                                <v-btn
                                    style="float: right;"
                                    class="ma-2"
                                    outlined
                                    fab
                                    x-small
                                    color="teal"
                                    @click="showInvoiceDialog"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                <span>{{ order.invoice.nip }}</span><br/>
                                <span v-if="order.invoice.companyName">{{ order.invoice.companyName }}</span><br/>
                                <span v-if="order.invoice.address">{{ order.invoice.address }}</span><br/>
                                <span v-if="order.invoice.postCode && order.invoice.city">{{ order.invoice.postCode }} {{ order.invoice.city }}</span><br/>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn
                            class="text-capitalize mt-2"
                            outlined
                            color="purple lighten-2"
                            small
                            @click="showPaymentHistory"
                        >
                            Historia płatności
                        </v-btn>
                        <v-spacer/>
                        <v-btn
                            class="text-capitalize mt-2"
                            outlined
                            small
                            color="teal lighten-2"
                            @click="payForOrder"
                        >
                            Zapłać
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>

            <v-col cols="12"  md="4" xs="12" class="text-left d-flex flex-column">
                <v-card
                    class="flex"
                >   
                    
                    <v-card-text>
                        <div class="black--text mb-2 font-weight-medium">Czas realizacji</div>
                                                                    
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-fast-forward-10</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap">Czas realizacji 7-10 dni roboczych</v-list-item-title>
                                <v-list-item-subtitle class="text-wrap">(od momentu akceptacji projektu)</v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                                                                          
                                     
                                              
                                                               
                                               
                                                 
                                                                                                                           
                                                                                                                               
                                                  
                                      
                                  
                        
                                                                    
                        <v-list-item>
                            <v-list-item-icon>
                                <v-icon>mdi-star-check-outline</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title class="text-wrap teal--text text-darken-4">Usługa Express: czas realizacji 3-4 dni robocze </v-list-item-title>
                                <v-list-item-subtitle class="text-wrap red--text"> 
                                    <v-btn
                                        color="teal lighten-2"
                                        class="text-capitalize"
                                        outlined
                                        small
                                        v-if="!order.isExpress"
                                        :disabled="editBlock"
                                        @click="confirmExpress"
                                    >
                                        Wykup
                                    </v-btn>
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                                  
                        <!-- CZAS WYSYLKI WIDOK KTORY BEDZIE UZYTY JAK BEDZIE KONKRETNA DATA OBLICZANA -->
                        <!-- <div class="black--text mb-2 font-weight-medium">Czas wysyłki</div>
                        <v-row no-gutters>
                            <v-col cols="12" md="5" sm="5" > Szacowany czas wysyłki: </v-col>
                            <v-col cols="12" md="7" sm="7" class="black--text">
                                <v-chip
                                    color="purple lighten-2 mr-2"
                                    class="white--text"
                                    small
                                >{{getDelvieryTime()}}</v-chip>
                            </v-col>
                            <v-col cols="12" md="5" sm="5" class="mt-2"> Wysyłka usługą ekspres: </v-col>
                            <v-col cols="12" md="7" sm="7" class="black--text mt-2">
                                <v-chip
                                    color="teal lighten-2 mr-2"
                                    class="white--text"
                                    small
                                >{{getDelvieryTimeExpress()}}</v-chip>

                                <v-btn
                                    color="teal lighten-2"
                                    class="text-capitalize"
                                    outlined
                                    small
                                    v-if="!order.isExpress"
                                    :disabled="order.status == 'send'"
                                    @click="confirmExpress"
                                >
                                    Wykup
                                </v-btn>
                            </v-col>
                            <v-col  cols="12" md="12" sm="12" class="black--text mb-5" v-if="getDelvieryTime().endsWith('*') || getDelvieryTimeExpress().endsWith('*')">
                                * - od momentu przekazania zamówienia do druku
                            </v-col>
                        </v-row> -->
                        <div class="black--text mb-2 font-weight-medium mt-2">Dostawa</div>    
                        <v-row>
                            <v-col cols="12" md="5" sm="5"> Przewoźnik: </v-col>
                            <v-col cols="12" md="7" sm="7" class="black--text">
                                <span v-if="order.delivery.typeId">
                                    {{getDeliveryName(order.delivery.typeId)}}
                                </span>
                                <span v-else>Brak wybranego</span>
                                <a class="ml-1" href="#" @click.prevent="editDelivery">
                                    <span v-if="order.delivery.typeId">(zmień)</span>
                                    <span v-else>(dodaj)</span>
                                </a>
                                <br/>
                                <span v-if="order.delivery.typeId" class="text-caption grey--text">({{order.delivery.price}} zł)</span>
                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="5" sm="5">
                                Dane kontaktowe:
                            </v-col>
                            <v-col cols="12" md="7" sm="7" class="black--text">
                                {{ order.customer.name }}<br/>
                                {{ order.customer.email }}<br/>
                                {{ order.customer.phone }}
                                
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" md="5" sm="5" >
                                <span v-if="selectedTypeModule == 'inpost' && order.delivery.data && order.delivery.data.pointCode">Paczkomat:</span>  
                                <span v-else>Adres dostawy:</span>
                            </v-col>
                            <v-col cols="12" md="7" sm="7" class="black--text">
                                <v-btn
                                    style="float: right;"
                                    class="ma-2"
                                    outlined
                                    fab
                                    x-small
                                    color="teal"
                                    @click="editDelivery"
                                >
                                    <v-icon>mdi-pencil</v-icon>
                                </v-btn>
                                
                                <span v-if="order.delivery.data && Object.keys(order.delivery.data).length && selectedTypeModule !='pickup'">
                                    <span v-if="selectedTypeModule == 'inpost'">
                                        <span v-if="order.delivery.data.pointCode">{{order.delivery.data.pointCode}}<br/></span>
                                        <span v-if="order.delivery.data.addressline1">{{order.delivery.data.addressline1}}<br/></span>
                                        <span v-if="order.delivery.data.addressline2">{{order.delivery.data.addressline2}}<br/></span>
                                        <span class="teal--text text-caption"  v-if="order.delivery.data.isCod">(Wybrano opcje za pobraniem)<br/></span>
                                    </span>
                                    <span v-if="selectedTypeModule == 'carrier'">
                                        <span v-if="order.delivery.data.name">{{order.delivery.data.name}}<br/></span>
                                        <span v-if="order.delivery.data.address">{{order.delivery.data.address}}<br/></span>
                                        <span v-if="order.delivery.data.postCode">{{order.delivery.data.postCode}}</span>
                                        <span class="ml-1" v-if="order.delivery.data.city">{{order.delivery.data.city}}</span>
                                        <span class="ml-1" v-if="order.delivery.data.country">({{order.delivery.data.country}})</span>
                                    </span>
                                    <br v-if="order.delivery.data.phone" />
                                    <span v-if="order.delivery.data.phone">{{order.delivery.data.phone}}</span>
                                </span>
                                <span  v-if="order.delivery.data && Object.keys(order.delivery.data).length == 0 && selectedTypeModule !='pickup'">
                                    <v-icon color="red" >mdi-alert-circle-outline</v-icon>
                                    <span class="red--text ml-1 mt-1">Brak adresu dostawy</span> 
                                </span>
                            </v-col>
                            
                             
                        </v-row>
                    </v-card-text>
                    
                </v-card>
            </v-col>

            <v-col cols="12"  md="4" xs="12" class="text-left d-flex flex-column">
                                                                
                                        
                          
                <v-card
                    class="flex"
                >
                    <v-card-text>
                        <div class="black--text mb-2 font-weight-medium">Dodatkowe informacje</div>
                        <v-row no-gutters>
                                                                        
                            <v-col cols="12" md="6" sm="6" style="margin-top:10px;">
                                Data uroczystości:
                            </v-col>
                            <v-col cols="12" md="6" sm="6" class="black--text">
                                <v-menu
                                    v-model="eventDateMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="eventDate"
                                            prepend-icon="mdi-calendar"
                                            placeholder="Data uroczystości"
                                            readonly
                                            outlined
                                            dense
                                            hide-details
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="eventDate"
                                        @input="updateOrder"
                                        locale="pl"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            
                            
                            <v-col cols="12" md="12" class="text-caption purple--text">
                                Prosimy o podanie daty uroczystości. Pomoże nam to lepiej obsłużyć Państwa zamówienie.
                            </v-col>
                                      
                            
                            <v-col cols="12" md="6" sm="6" style="margin-top:20px;">
                                Uwagi do zamówienia:
                            </v-col>
                            <v-col cols="12" md="12" sm="12" v-if="notes.length > 0">
                                <span class="grey--text" style="font-size:12px;" v-for="n in sortNotes" :key="n._id"> 
                                    <span>Dodano: {{n.createdAt  | moment("DD/MM/YY HH:mm")}}</span><br/>
                                    <nl2br tag="p" :text="n.content" />
                                    <v-divider/>
                                
                                </span>
                            </v-col>
                            <v-col cols="12" md="12" sm="6" style="margin-top:20px;">
                                <v-textarea
                                    auto-grow
                                    rows="3"
                                    outlined
                                    hide-details
                                    label="Uwagi do zamówienia"
                                    v-model="noteContent"
                                ></v-textarea>
                                <v-btn
                                    class="text-capitalize mt-2"
                                    outlined
                                    color="purple lighten-2"
                                    small
                                    @click="addNote"
                                    :disabled="disabled"
                                >
                                    Dodaj
                                </v-btn>
                            </v-col>
                        </v-row>
                             
                    </v-card-text>
                        
                </v-card>
            </v-col>
            
        </v-row>
        
    </v-container>
</template>

<script>

import Utils from '../../Utils';
import Api from '../../CustomerApi';
import Confirm from '../common/Confirm.vue';
import TextEditDialog from '../common/TextEditDialog.vue';
import ExpressDelvieryDialog from '../common/ExpressDelvieryDialog.vue';
import DeliveryAddressDialog from '../common/DeliveryAddressDialog.vue';
import QtyEditDialog from '../common/QtyEditDialog.vue';
import AddProductDialog from '../common/AddProductDialog.vue';
import CustomCartPosDialog from '../common/CustomCartPosDialog.vue';
import PaymentOrderDialog from './PaymentOrderDialog.vue';
import PaymentHistoryDialog from '../common/PaymentHistoryDialog.vue';
import InvoiceDialog from '../common/InvoiceDialog.vue';
import LogsDialog from '../common/LogsDialog.vue';
import GuestListEditDialogVue from '../common/GuestListEditDialog.vue';
import PaidDetailsSelectorDialog from '../common/PaidDetailsSelectorDialog.vue';
import OrderStatusChip from '../common/OrderStatusChip.vue';
import Files from './Files.vue';

const moneyFormat = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });

const STATUSES_PROJECT_MAP = {
    'accepted':   { name:'Zaakceptowany', color:'teal accent-4'},
    'waiting':        { name:'Oczekujący', color:'light-blue accent-4'},
    'rejected': { name:'Odrzucony', color:'red accent-4'},
}
export default {
    components: {
        Confirm,
        Files,
        QtyEditDialog,
        OrderStatusChip
    },
    data: () => ({
        disabled:false,
        isLoading: true,
        order: null,
        details: false,
        eventDateMenu: false,
        eventDate:"",
        backUrl: null,
        headers: [
            { text: 'Produkt', align: 'start', sortable: false, value: 'name', },
            { text: 'Cena jednostkowa', value: 'price',  sortable: false  },
            { text: 'Zamówiona ilość', value: 'qty',  sortable: false  },
            { text: 'Wartość', value: 'wholePrice',  sortable: false  },
            { text: 'Treści', value: 'texts',  sortable: false  },
            { text: 'Lista gości', value: 'guestsList',  sortable: false  },
            { text: 'Projekt', value: 'projectLink', sortable: false  },
            { text: 'Status projektu', value: 'projectStatus', sortable: false  },
            { text: '', value: 'actions', sortable: false },
            
        ],
        orderContents: [],
        deliveryTypes: [],
        rabatCode: "",
        notes: [],
        noteContent:"",
        payments: [],
        states: [],
        features: [],
        stPMap: STATUSES_PROJECT_MAP,
        selectedTypeModule:""
        
    }),

    computed: {
        editBlock() {
            return this.order && this.order.status && this.order.status.isLocking;
        },

        sortNotes() {
            return [...this.notes].sort(function(a, b) {
                let dateA = new Date(a.createdAt), dateB = new Date(b.createdAt);
                return dateB - dateA;
            });
        },

        leftToPay() {
            return this.totalToPay - this.totalPaid;
        },

        totalToPay() {
            let p = this.order.prices;
            return p.products + p.delivery - p.discounts + p.express;
        },

        totalPaid() {
            let total = 0;
            for (let i = 0; i < this.payments.length; i++) {
                if (this.payments[i].status != "completed") continue;
                total += this.payments[i].value;
            }
            return total;
        },

        totalPendingPayments() {
            let total = 0;
            for (let i = 0; i < this.payments.length; i++) {
                if (this.payments[i].status == "waiting" || this.payments[i].status == "new") total += this.payments[i].value;
            }
            return total;
        },

        isInpostDelivery() {
            return this.order.delivery && this.order.delivery.data && this.order.delivery.data.pointCode;
        },

        warnings() {
            let r = [];
            return r;
            
            // W dniu 27.06.2024 o 12:51, szymon@wydrukujtu.pl pisze:
            // Po złożeniu zamówienia rezygnujemy z wyświetlania błędów (braków) oprócz informacji o braku adresu do wysyłki,
            // ale tu trzeba zmienić trigger. Bo wystarczy, że klient wybierze przewoźnika, ale nie poda adresu i ten komunikat
            // się nie wyświetla. Więc musimy sprawdzić czy np. Jest kod pocztowy
            // for (let i = 0; i < this.orderContents.length; i++) {
            //     const orderPos = this.orderContents[i];

            //     let neededDetailsCount = Object.keys(orderPos.details || {}).length;
            //     let providedDetailsCount = Object.keys(orderPos.details || {}).filter(key => orderPos.details[key] != null).length;

            //     if (providedDetailsCount < neededDetailsCount) {
            //         r.push({
            //             code: 'missing.details',
            //             orderPos
            //         });
            //     }

            //     if (Array.isArray(orderPos.variants) && orderPos.variants.length) {
            //         for (let y = 0; y < orderPos.variants.length; y++) {
            //             const variant = orderPos.variants[y];
            //             if (variant.needGuestList && !variant.guestList) {
            //                 r.push({
            //                     code: 'missing.guest-list',
            //                     orderPos,
            //                     variant
            //                 });
            //             }

            //             if (variant.needContent && !variant.content) {
            //                 r.push({
            //                     code: 'missing.content',
            //                     orderPos,
            //                     variant
            //                 });
            //             }
            //         }
            //     }
            // }
            // return r;
        },

        products() {
            let p = [];
            for (let i = 0; i < this.orderContents.length; i++) {
                const orderPos = this.orderContents[i];
                let neededDetailsCount = Object.keys(orderPos.details || {}).length;
                let providedDetailsCount = Object.keys(orderPos.details || {}).filter(key => orderPos.details[key] != null).length;
                let paidDetailsTxt = "";
                for (let featureId in (orderPos.paidDetails || {})) {
                    let feature = this.features.find(f => f._id == featureId);
                    if (!feature) continue;

                    paidDetailsTxt += feature.name + ": " + orderPos.paidDetails[featureId];
                }

                if (Array.isArray(orderPos.variants) && orderPos.variants.length) {
                    for (let y = 0; y < orderPos.variants.length; y++) {
                        const variant = orderPos.variants[y];
                        p.push({
                            orderPos,
                            variant,
                            neededDetailsCount,
                            providedDetailsCount,
                            name: variant.name == "#default" ? "Ilość standardowa" : variant.name,
                            paidDetails: paidDetailsTxt.length ? paidDetailsTxt : null,
                            categoryTitle: orderPos.name,
                            price: moneyFormat.format(orderPos.price),
                            qty: variant.qty,
                            wholePrice: moneyFormat.format(variant.qty * orderPos.price),
                            category: orderPos._id,
                            guestsList: variant.needGuestList ? (variant.guestList ? 'ok' : 'error') : false,
                            texts: variant.needContent ? (variant.content ? 'ok' : 'error') : false 
                        });
                    }
                } else {
                    p.push({
                        orderPos,
                        neededDetailsCount,
                        providedDetailsCount,
                        name: orderPos.name,
                        price: moneyFormat.format(orderPos.price),
                        qty: orderPos.qty,
                        wholePrice: moneyFormat.format(orderPos.qty * orderPos.price),
                        guestsList: false
                    });
                }

                if (Array.isArray(orderPos.surcharges) && orderPos.surcharges.length) {
                    for (let z = 0; z < orderPos.surcharges.length; z++) {
                        p.push({
                            orderPos,
                            isPermanent: true,
                            variant: {},
                            neededDetailsCount,
                            providedDetailsCount,
                            name: orderPos.surcharges[z].name,
                            price: moneyFormat.format(orderPos.surcharges[z].value),
                            qty: 1,
                            wholePrice: moneyFormat.format(orderPos.surcharges[z].value),
                            category: orderPos._id,
                            guestsList: false,
                            texts: false 
                        });
                    }
                } else if (orderPos.surcharge && orderPos.surcharge.value) {
                    p.push({
                        orderPos,
                        isPermanent: true,
                        variant: {},
                        neededDetailsCount,
                        providedDetailsCount,
                        name: orderPos.surcharge.name,
                        price: moneyFormat.format(orderPos.surcharge.value),
                        qty: 1,
                        wholePrice: moneyFormat.format(orderPos.surcharge.value),
                        category: orderPos._id,
                        guestsList: false,
                        texts: false 
                    });
                }
            }

            return p;
        }
    },

    async created () {
        this.isLoading = true;
        let orderUUID = this.$route.params.orderUUID;
        if (!orderUUID) return this.$router.push({ name: 'cart' });

        // let orderUUID = localStorage.getItem(config.CUSTOMER_LS_KEY);
        // if (!orderUUID) return this.$router.push({ name: 'cart' });

        try {
            Api.setToken(orderUUID);
            this.states = await Api.order.getOrderStates();
            let projectReadyStateId = await Api.order.getProjectReadyState();
            console.log(projectReadyStateId);
            let o = await Api.order.get();
            if (!o.delivery) o.delivery = { data: {} };
            
            o.status = this.states.find(s => s._id == o.statusId || "");
            if (!o.status) o.status = { name: "Brak statusu", color: "#223344" };
            if (o.status._id == projectReadyStateId) o.projectReady = true;

            this.order = o;
            if (!this.order || !this.order.isPlaced)return this.$router.push({ name: 'cart' });
            if(this.order.eventDate) this.eventDate = this.order.eventDate.split("T")[0];
            this.orderContents = await Api.order.getContent();
            let all = await Api.delivery.search({});
            all.sort((a, b) => a._priority - b._priority);
            this.deliveryTypes = all.filter(c => !c.isRemoved);
            this.notes = await Api.customer.getNotes();
            this.payments = await Api.payments.get();
            this.features = await Api.features.search({});
        } catch (error) {
            this.$toast.error("Wystąpił problem podczas ładowania koszyka");
            console.error(error);
        }
        this.getSelectedModule();
        this.isLoading = false;

        if (window.wph) {
            window.wph('track', 'ViewContent', { 'content_name': 'Configurator' });
        }
    },

    methods: {
        getSelectedModule() {
            if(this.order.delivery.typeId) this.selectedTypeModule = this.deliveryTypes.filter(d => d._id == this.order.delivery.typeId)[0].module;
        },
        async payForOrder() {
            let editedText = await this.$modal.show(PaymentOrderDialog, {toPay:this.leftToPay, orderNum: this.order.code});
            if (editedText) {
                // w odpowiedzi od API dostajemy przeliczona cala pozycje
                this.$toast.success("Zmiana zapisana");
            }
        },
        async addPersonalCartPosition(idPos) {
            let customPos = await this.$modal.show(CustomCartPosDialog);
            if (customPos) {
                if (this.editBlock) return this.$toast.info("Przepraszamy, ale na tym etapie zamówienia nie można dodawać nowych pozycji");
                let newPos = await Api.order.addCustomPos(idPos, customPos);
                let idx = this.orderContents.map(c => c._id).indexOf(idPos);
                if(idx != -1) this.$set(this.orderContents, idx, newPos);

                await this.fetchOrderPrices();
                this.$toast.success("Zamiana zapisana");
            }
        },
        async showInvoiceDialog() {
            let inv = this.order.invoice ? this.order.invoice : {};
            let invoice = await this.$modal.show(InvoiceDialog, {invoiceData: inv});
            if (invoice !== false) {
                let o =  await Api.order.update({invoice:invoice});
                this.$set(this.order, "invoice", o.invoice);
                this.$toast.success("Zmiana zapisana");
            }
        },
        async editDelivery() {
            let editedDelivery = await this.$modal.show(DeliveryAddressDialog, {delveryTypes: this.deliveryTypes, orderDelivery: this.order.delivery}, {width:800});
            if (editedDelivery !== false) {
                // w odpowiedzi od API dostajemy przeliczona cala pozycje
                let o =  await Api.order.setDelivery(editedDelivery.typeId, editedDelivery.data);
                this.$set(this.order, "delivery", o.delivery);
                this.$set(this.order, "prices", o.prices);
                this.getSelectedModule();
                this.$toast.success("Zmiana zapisana");
            }
        },
        async confirmExpress() {
            let ekspress = await this.$modal.show(ExpressDelvieryDialog, { order: this.order });
            if (ekspress != false) {
                let o = await Api.order.enableExpress();
                this.order.isExpress = o.isExpress;
                Object.assign(this.order.prices, o.prices);
                this.$toast.success("Dodano usługę ekspres!");
            }
        },
        async editVariantQty(cartPos, variant) {
            let editedQty = await this.$refs.qtyedit.open('Zmień ilość sztuk', variant.qty, cartPos.price);
            if (editedQty !== false) {
                if (this.editBlock) return this.$toast.info("Przepraszamy, ale na tym etapie zamówienia nie można zmienić jego szczegółów");
                let oldQty = variant.qty;
                this.$set(variant, "qty", editedQty);

                // w odpowiedzi od API dostajemy przeliczona cala pozycje
                try {
                    let updCartPos = await Api.order.updateVariant(cartPos._id, variant._id, {qty: editedQty});
                    cartPos.qty = updCartPos.qty;
                    cartPos.surcharge = updCartPos.surcharge;
                    cartPos.surcharges = updCartPos.surcharges;
    
                    await this.fetchOrderPrices();
                    this.$toast.success("Zmiana zapisana");
                } catch (error) {
                    this.$set(variant, "qty", oldQty);
                    this.$toast.error("Nie można zmienić ilości. Minimalna ilość dla tego produktu to "+cartPos.minQty+"szt");
                }
            }
        },

        async editTextsInVariant(cartPos, variant) {
            let editedText = await this.$modal.show(TextEditDialog, { text: variant.content, showWarning: this.order.projectReady });
            if (editedText) {
                if (this.editBlock) return this.$toast.info("Przepraszamy, ale na tym etapie zamówienia nie można zmienić jego szczegółów");
                this.$set(variant, "content", editedText);
                await Api.order.updateVariant(cartPos._id, variant._id, {content: editedText});
                this.$toast.success("Zamiana zapisana");
            }
        },

        async editPaidDetails(cartPos) {
            let editedDetails = await this.$modal.show(PaidDetailsSelectorDialog, {basePrice: cartPos._basePrice, details: cartPos.paidDetails, features: this.features});
            if (editedDetails) {
                let resCartPos = await Api.order.updateContent(cartPos._id, {paidDetails: editedDetails});
                this.$set(cartPos, "paidDetails", resCartPos.paidDetails);
                cartPos.price = resCartPos.price;
                await this.fetchOrderPrices();
                this.$toast.success("Zmiana zapisana");
            }
        },

        async editGuestListInVariant(cartPos, variant) {
            let editedText = await this.$modal.show(GuestListEditDialogVue, {text: variant.guestList, cartPosName: cartPos.name});
            if (editedText) {
                if (this.editBlock) return this.$toast.info("Przepraszamy, ale na tym etapie zamówienia nie można zmienić jego szczegółów");
                var filtered = editedText.split(/\r\n|\r|\n/).filter(function (el) { return el != ""; });
                if(filtered.length > 0) this.$set(variant, "qty", filtered.length);
                this.$set(variant, "guestList", editedText);
                let updCartPos = await Api.order.updateVariant(cartPos._id, variant._id, {guestList: editedText, qty:filtered.length});
                cartPos.qty = updCartPos.qty;
                cartPos.surcharge = updCartPos.surcharge;
                cartPos.surcharges = updCartPos.surcharges;
                await this.fetchOrderPrices();
                this.$toast.success("Zamiana zapisana");
            }
        },

        gotoDetails(orderPosId) {
            // this.$router.push("/product-details");
            this.$router.push({
                name: 'product-details',
                params: {
                    cartPosId: orderPosId,
                    orderUUID: this.order.uuid
                }
            })
        },

        async deleteCartPos(cartPosId) {
            let idx = this.orderContents.map(pos => pos._id).indexOf(cartPosId);
            if (idx == -1) return;

            let pos = this.orderContents[idx];
            if (await this.$refs.confirm.open('Usuń produkt z koszyka', 'Czy na pewno chcesz usunąć pozycję ' + pos.name + '?', { color: 'red' })) {
                this.orderContents.splice(idx , 1);
                await Api.order.removeContent(cartPosId);
                await this.fetchOrderPrices();
            }
        },
        async deleteCartPosVariant(cartPosId, variant) {
            if (await this.$refs.confirm.open('Usuń pozycje produkt z koszyka', 'Czy na pewno chcesz usunąć pozycję ' + variant.name + '?', { color: 'red' })) {
                await Api.order.removeCartPos(cartPosId,variant._id);
                await this.fetchOrderPrices();
                let idx = this.orderContents.map(pos => pos._id).indexOf(cartPosId);
                let pos = this.orderContents[idx];
                let vIdx = pos.variants.map(v => v._id).indexOf(variant._id);
                if(vIdx != -1) {
                    pos.variants.splice(vIdx , 1);
                    if (pos.variants.length == 0) this.orderContents.splice(idx , 1);
                }
            }
        },
        
        getDeliveryName(id) {
            let idx =  this.deliveryTypes.map(d => d._id).indexOf(id);
            if(idx != -1) return this.deliveryTypes[idx].name;
            else "Brak nazwy";
        },
        changeRabat() {

        },
        async addNote() {
            if(this.noteContent.length == 0) return;
            this.disabled = true;
            try {
                let n = await Api.customer.addNote({orderId:this.order._id, content:this.noteContent});
                this.notes.push(n);
                this.noteContent = "";
                this.$toast.success("Pomyślnie dodano");
            } catch (error) {
                console.log(error);
            }
            this.disabled = false;
        },
        
        getFeatureName(id) {
            let idx = this.features.map(s => s._id).indexOf(id);
            if(idx != -1) return this.features[idx].name;
            else return "Brak nazwy";
        },
        async addProduct() {
            let addedProduct = await this.$modal.show(AddProductDialog, {}, {width:800});
            if (addedProduct) {
                this.orderContents.push(addedProduct);
                this.$toast.success("Zmiana zapisana " + addedProduct);
            }
        },
        showModalForWarring(orderPos, variant, code) {
            if(code == 'missing.guest-list') this.editGuestListInVariant(orderPos, variant);
            if(code == 'missing.content') this.editTextsInVariant(orderPos, variant);
            if(code == 'missing.details') this.gotoDetails(orderPos._id);
        },

        async showPaymentHistory() {
            await this.$modal.show(PaymentHistoryDialog,{payments: this.payments} );
        },

        // Robi update cen zamowienia
        async fetchOrderPrices() {
            try {
                let o = await Api.order.get();
                Object.assign(this.order.prices, o.prices);
            } catch (error) {
                console.log(error)
            }
        },

        async updateOrder() {
            try {
                this.order.eventDate = this.eventDate;
                this.eventDateMenu = false;
                Api.order.update({eventDate: this.eventDate});
                this.$toast.success("Zamiana zapisana");
            } catch (error) {
                console.log(error)
            }
        },
        async showLogs() {
            await this.$modal.show(LogsDialog,{orderContents:this.orderContents, features: this.features, types: this.deliveryTypes, from:'client'}, {width:900});
        },

        getDelvieryTime() {
            if (this.order.closeDate) return this.order.closeDate.split("T")[0];
            else return "10 dni roboczych*" 
        },

        getDelvieryTimeExpress() {
            if (this.order.closeDate && this.order.isExpress) return this.order.closeDate.split("T")[0];
            return "4 dni robocze*"
        }
    },
}
</script>
<style>
.theme--light.v-table tbody tr td {
    border-bottom: none;
}
</style>
<style lang="scss" scoped>
@import '../../sass/variables.scss';
.w-green {
    color: $w-green;
}
.b-pink {
background-color: $w-pink;
}
</style>
