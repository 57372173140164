<template>
    <v-chip :color="color" class="white--text" small>
        {{ name }}
        <v-icon v-if="status.isLocking" class="ml-1" small color="" >
            mdi-shield-lock
        </v-icon>
    </v-chip>
</template>

<script>

export default {
    props: {
        status: Object
    },
    data: () => ({
    }),
    computed: {
        color() {
            return this.status ? (this.status.color || 'grey') : 'grey';
        },

        name() {
            return this.status ? (this.status.name || 'Unknown') : 'Unknown';
        },
    },
    methods: {
    }
}
</script>