module.exports = {
    getExtensionForMime(mime) {
        if (mime.indexOf('application/vnd.oasis.opendocument.spreadsheet') != -1) return 'ods';
        else if (mime.indexOf('image/png') != -1) return 'png';
        else if (mime.indexOf('image/jpeg') != -1) return 'jpg';
        else if (mime.indexOf('application/pdf') != -1) return 'pdf';
        else return null;
    },

    /**
     * Zwraca tablice dat zawartych w danym zakresie
     * @param {Date} startDate 
     * @param {Date} endDate 
     * @returns {Date[]}
     */
    getDatesBetween(startDate, endDate) {
        let dates = [];
        let currentDate = new Date(startDate);
        let addDays = function(days) {
            var date = new Date(this.valueOf());
            date.setDate(date.getDate() + days);
            return date;
        };

        while (currentDate <= new Date(endDate)) {
            dates.push(currentDate);
            currentDate = addDays.call(currentDate, 1);
        }
        return dates;
    },

    
    /**
     * Konwertuje licze bitów na czytelny format rozmiaru, np 3MB
     * @param {Number} bytes      size in bytes
     * @param {Number} [decimals] wtf?
     * @returns {string}
     */
    formatBytes(bytes, decimals) {
        if(bytes == 0) return '0 Bytes';
        let k = 1024,
            dm = decimals <= 0 ? 0 : decimals || 2,
            sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'],
            i = Math.floor(Math.log(bytes) / Math.log(k));

        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },

    getGTMTransactionData(order, orderContents) {
        let products = orderContents.map(orderPos => {
            return {
                name:     orderPos.name,
                id:       orderPos.product.id,
                price:    orderPos.price,
                quantity: orderPos.qty,
            }
        });

        let p = order.prices || {};
        let revenue = p.products + (p.delivery || 0) - p.discounts + p.express;

        return {
            event: "transaction",
            ecommerce: {
                purchase: {
                    actionField: {
                        id: order.code,                // Transaction ID. Required for purchases and refunds.
                        revenue,                       // Total transaction value (incl. tax and shipping)
                        tax: revenue - (revenue/1.23),
                        shipping: p.delivery,
                    },

                    // List of productFieldObjects
                    products
                }
            }
        }
    }
};