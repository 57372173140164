<template>
    <v-card class="mb-1">
        <v-toolbar color="teal darken-3" dark>
            <v-toolbar-title>Pliki do zamówienia</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="overlay=true; $refs.files.click()">
                <v-icon>mdi-upload-outline</v-icon>
            </v-btn>
            <input type="file" multiple ref="files" @change="onFilesInputChange" style="display: none;">
        </v-toolbar>
            
        <div v-if="uploadProgress != null">
            <v-progress-circular
                :size="100"
                :width="30"
                :value="uploadProgress"
                color="deep-orange"
            >
                {{ uploadProgress }}
            </v-progress-circular>
            <v-btn @click="overlay=false"><v-icon color="grey lighten-1">mdi-upload</v-icon> Prześlij plik</v-btn>
        </div>

        <div v-else>
            <v-list
                v-if="files.length"
                two-line
            >
                <v-list-item
                    v-for="file in files"
                    :key="file._id"
                >
                    <v-list-item-avatar>
                        <v-icon
                            :class="file.color"
                            dark
                            v-text="file.icon"
                        ></v-icon>
                    </v-list-item-avatar>

                    <v-list-item-content>
                        <a target="_blank" :href="file.href" :title="'Zobacz plik: ' + file.name">{{ file.name }}</a>
                        <v-list-item-subtitle v-text="file.formatedSize"></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                        <v-btn icon @click="$toast('uhuhuh')">
                            <v-icon  color="grey lighten-1">mdi-close</v-icon>
                        </v-btn>
                    </v-list-item-action>
                </v-list-item>
            </v-list>
            <v-card-text v-else>
                Brak plików
            </v-card-text>
        </div>
        
    </v-card>
</template>

<script>
import Api from '../../CustomerApi.js'

const Utils = require('../../Utils');

export default {
    props:{
        order: Object
    },
    data () {
        return {
            loading: false,
            overlay: false,
            // uploadProgress: null,
            uploads: []
        }
    },
    created: async function() {
        this.loading = true;
        try {
            // this.services = await Api.extServices.search({});
        } catch (error) {
            console.log(error)
        }
        this.loading = false;
    },
    computed: {
        files() {
            if (!this.order || !this.order.files) return [];
            return this.order.files.map(file => {
                return Object.assign({
                    ext: Utils.getExtensionForMime(file.mime),
                    formatedSize: Utils.formatBytes(file.size),
                    icon: this.getIconCodeForMime(file.mime),
                    href: Api.getFileUrl(file.key, file.name),
                    color: 'grey'
                }, file);
            })
        },

        uploadProgress() {
            let uploadings = this.uploads.filter(u => u.isCompleted == false);
            if (!uploadings.length) return null;

            let t = 0;
            for (let i = 0; i < uploadings.length; i++) t += uploadings[i].progress;

            return t / uploadings.length;
        }
    },
    
    methods: {
        onFilesInputChange(e) {
            const uploadedFiles = this.$refs.files.files;
            this.handleFilesUpload(uploadedFiles);
        },

        handleFilesUpload(uploadedFiles) {
            for (var i = 0; i < uploadedFiles.length; i++) {
                const fObj = {
                    file: uploadedFiles[i],
                    progress: 0,
                    isError: false,
                    isCompleted: false
                };

                this.uploads.push(fObj);

                const formData = new FormData();
                formData.append('file', uploadedFiles[i]);

                Api.uploadFile(formData, (progressEvent) => {
                    fObj.progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total));
                }).then(async (ob) => {
                    fObj.isCompleted = true;
                    let newOrder = await Api.order.addFile({key:ob.fileKey, name:ob.fileName});
                    this.order.files.splice(0);
                    this.order.files.push(...newOrder.files);
                    // this.$set(this.order, "files", newOrder.files)
                }).catch((reason) => {
                    console.warn(reason);
                    if (reason.status == 429) this.$toast.warning("Przekroczono limit wysyłanych plików.\nProsimy spróbować ponownie za 15min");
                    else this.$toast.error("Wystąpił problem podczas wysyłania pliku");
                    fObj.isCompleted = true;
                });
            }
        },

        getIconCodeForMime(mime) {
            if (mime.indexOf('image/jpeg') != -1) return 'mdi-file-image-outline'
            else return 'mdi-file'
        }
    }
}


</script>