<template>
    <v-card elevation="1" class="mb-5 mt-3 left-bordered-primary">
        <div class="ml-5 pt-2  font-weight-light">Logi wszystki zmian</div>
        <v-divider class="mt-2" />  
        <v-data-table
            :headers="headersLogs"
            :items="sortLogs"
            class="elevation-1 row-pointer"
            :loading="loading"
            loading-text="Loading... Please wait"
            :sort-desc="[false, true]"
            :hide-default-header="true"
            :items-per-page="5"
        >
            <template v-slot:item.code="{ item }">
                <p class="text-caption mb-0" v-if="logsCodes[item.code]">{{logsCodes[item.code].name}}</p>
                <!-- <span class="font-weight-bold" v-if="item.params.id">{{getProductsName(item.params.id)}}</span> -->
                <span v-else>Brak kodu !</span>
                <span class="ml-2 font-weight-bold" v-if="item.code == 'order.delivery-update'">{{getDeliveryName(item.params.deliveryTypeId)}}</span>
                <span class="ml-2 font-weight-bold" v-if="item.code == 'order.status-update' && orderStatuses[item.params.status]">{{orderStatuses[item.params.status].name}}</span>
                <span class="ml-2 font-weight-bold" v-if="item.code == 'order-pos.price-update' && item.params.price">na {{ item.params.price | money}}/szt</span>
            </template>

            <template v-slot:item.params="{ item }">
                <span class="font-weight-bold" v-if="item.params.id">{{getProductsName(item.params.id)}}</span>
                <span class="font-weight-bold" v-if="item.params.orderPositionId">{{getProductsName(item.params.orderPositionId)}}</span>
                <span v-if="item.code == 'order-pos.update'">
                    <span v-if="item.params.details">
                        <p class="grey--text text-caption mb-0">Zmieniono:</p>
                        <p class="mb-0" v-for="(details, i) in item.params.details" :key=i>
                            {{getFeatureName(i)}} 
                            <span v-if="details"> na <span class="font-weight-bold">{{details}}</span></span>
                            <span  v-else> - <span class="red--text">brak podanego</span></span>
                        </p>
                    </span>
                    <span v-if="item.params.paidDetails">
                        <p class="grey--text text-caption mb-0">Zmieniono płatny atrybut:</p>
                        <p class="mb-0" v-for="(details, i) in item.params.paidDetails" :key=i>
                            {{getFeatureName(i)}} 
                            <span v-if="details"> na <span class="font-weight-bold">{{details}}</span></span>
                            <span  v-else> - <span class="red--text">brak podanego</span></span>
                        </p>
                    </span>
                </span>
                <span v-if="item.code == 'order-variant.update'">
                    <p class="grey--text text-caption mb-0">Zmieniono:</p>
                    <p class="mb-0" v-if="item.params.name">Nazwę na <span class="font-weight-bold">{{item.params.name}}</span></p>
                    <p class="mb-0" v-if="item.params.guestList">Listę gości</p>
                    <p class="mb-0" v-if="item.params.qty">Ilość na <span class="font-weight-bold">{{item.params.qty}}</span></p>
                    <p class="mb-0" v-if="item.params.content">Treści</p>
                </span>
            </template>

            <template v-slot:item.at="{ item }">
                <span class="grey--text text-caption">{{item.at | moment("DD/MM/YY HH:mm")}}</span>
            </template>
            <template v-slot:item.by="{ item }">
                <span class="text-caption" v-if="item.by == 'user'">Dodał:<br/> Pracownik
                    <span class="gray--text" v-if="item.user && from == 'admin'">({{EmployeeManager.getName(item.user)}})</span>
                </span>
                <span class="text-caption" v-else>Dodał:<br/> Klient</span>
            </template>
        </v-data-table>
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import CustomerApi from '../../CustomerApi';
import Api from '../../Api';
import EmployeeManager from '../../EmployeeManager';
import orderStatuses from '../../common-data';
export default {
    props: {
        orderContents:Array,
        features: Array,
        types: Array,
        from: String,
        orderId:String
    },
    data: () => ({
        loading:false,
        logs: [],
        headersLogs: [
            { text: '', align: 'start', sortable: false, value: 'code', },
            { text: '', align: 'start', sortable: false, value: 'params', },
            { text: '', value: 'by',  sortable: false  },
            { text: '', value: 'at',  sortable: false  },
        ],
        logsCodes: {
            'order-pos.price-update':{name: "Zmieniono cenę produktu"},
            'order.product-add':{name: "Dodano produkt"},
            'order.status-update':{name: "Zmieniono status"},
            'order.express-update':{name: "Dodano usługe ekspress"},
            'order.update':{name: "Zmiana zamówienia"},
            'order.delivery-update':{name: "Zmiana sposobu dostawy"},
            'order.voucher-remove':{name: "Usunięto rabat"},
            'order.voucher-create':{name: "Dodano rabat"},
            'order-variant.update-project':{name: "Zmieniono wariant produktu"},
            'order-variant.update':{name: "Zmiana w wariancie"},
            'order-variant.create':{name: "Utworzono nowy wariant produktu"},
            'order-variant.remove':{name: "Usunięto nowy wariant produktu"},
            'order-pos.update':{name: "Zmiana pozycji"},
            'order-pos.remove':{name: "Usunięcie pozycji z zamówienia"},
        },
        orderStatuses:orderStatuses.STATUSES_MAP,
        deliveryTypes:[],
        EmployeeManager:EmployeeManager
        
    }),
    computed: {
        sortLogs() {
            return [...this.logs].sort(function(a, b) {
                var dateA = new Date(a.AT), dateB = new Date(b.AT);
                return dateB - dateA;
            });
        },
    },
    async created () {
        this.loading = true;
        try {
            if(this.from == 'admin') this.logs = await Api.order.getLogs(this.orderId);
            else this.logs = await CustomerApi.order.getLogs();
            this.deliveryTypes = Object.assign(this.deliveryTypes, this.types);
        } catch (error) {
            console.log(error)
        }
        this.loading = false;
    },
    methods: {
        getDeliveryName(id) {
            let idx =  this.deliveryTypes.map(d => d._id).indexOf(id);
            if(idx != -1) return this.deliveryTypes[idx].name;
            else "Brak nazwy";
        },
        getFeatureName(id) {
            let idx =  this.features.map(d => d._id).indexOf(id);
            if(idx != -1) return this.features[idx].name;
            else "Brak nazwy";
        },
        getProductsName(id) {
            let idx =  this.orderContents.map(d => d._id).indexOf(id);
            if(idx != -1) return this.orderContents[idx].name;
            else "Brak nazwy";
        },
        cancel() {
            this.$emit('close', null);
        },
    }
}
</script>
