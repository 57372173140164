<template>
    <v-card>
        <v-toolbar color="primary" dark dense flat>
            <v-toolbar-title class="white--text">Dodaj nowy produkt</v-toolbar-title>
            <v-progress-linear
                :active="isLoading"
                :indeterminate="isLoading"
                absolute
                bottom
                color="secondary"
            ></v-progress-linear>
        </v-toolbar>
        
        <v-card-text class="pa-4">
            <span class="ml-2">Szukaj produktu w naszym sklepie np Winetka Korani</span>
            <v-text-field
                class="search"
                v-model="searchQuery"
                @input="onQueryInput"
                prepend-inner-icon="mdi-magnify"
                placeholder="Szukaj"
                outlined
                rounded
                clearable
                dense
                hide-details
                color="grey lighten-2"
            >
            </v-text-field>
        </v-card-text>
        <span class="text-caption grey--text ml-4">Znaleziono:</span>
        <v-card class="mx-4 pa-0 mb-1" color="primary" outlined>
            <v-list three-line style="max-height: 400px; overflow-y:auto">
                <template v-for="(item) in listItems">
                    <v-subheader
                        v-if="item.header"
                        :key="item.header"
                        v-text="item.header"
                    ></v-subheader>

                    <v-divider
                        :key="item.id"
                    ></v-divider>

                    <v-list-item
                        class="pl-0"
                        :key="item.id"
                    >
                        <v-list-item-avatar class="mx-0 my-1" :rounded="false" width=320 min-height="200" size="200" style="border-radius:0">
                            <v-img contain :src="item.imgUrl"></v-img>
                        </v-list-item-avatar>

                        <v-list-item-content class="pt-0">
                            <v-list-item-title v-html="item.title"></v-list-item-title>
                            <v-list-item-subtitle class="text-caption" style="overflow-wrap: break-word;">{{ item.desc }}</v-list-item-subtitle>
                            <v-list-item-subtitle>{{ item.price }}</v-list-item-subtitle>
                            <v-row>
                                <v-col cols="12" md="12">
                                    <v-btn color="primary" outlined x-small>Zobacz zdjecia</v-btn>
                                    <v-btn :disabled="isLoading" color="primary" outlined x-small style="float:right" @click="add(item.id)">Dodaj do zamówienia</v-btn>
                                </v-col>
                            </v-row>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </v-card>

        <v-divider></v-divider>
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary darken-1" text @click.native="agree">Dodaj</v-btn> -->
            <v-btn color="grey" text @click.native="cancel">Cofnij</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Api from '../../CustomerApi';
import AdminApi from '../../Api';

const moneyFormat = new Intl.NumberFormat('pl-PL', { style: 'currency', currency: 'PLN' });
let searchTimeout = null;

export default {
    props: {
        scope: {
            type: String,
            default: "customer"
        },
        id: String
    },
    data: () => ({
        isLoading: false,
        searchQuery: "",
        products: [],
        orderId:""
    }),

    computed: {
        listItems() {
            return this.products.map(p => {
                return {
                    id: p.id,
                    title: p.name,
                    desc: p.description.replace(/<\/?[^>]+(>|$)/g, ""),
                    price: moneyFormat.format(p.priceBru),
                    imgUrl: (this.scope == "customer")  ? Api.products.getPrestaImageUrl(p.id, p.imageId) : AdminApi.products.getPrestaImageUrl(p.id, p.imageId),
                    isProcessing: false
                }
            });
        }
    },
    created() {
        this.orderId = this.id;
    },
    methods: {
        onQueryInput() {
            if (searchTimeout) clearTimeout(searchTimeout);
            if (this.searchQuery == "") return this.products = [];
            searchTimeout = setTimeout(() => {
                this.search();
            },1500);
        },

        async search() {
            this.isLoading = true;
            try {
                if (this.scope == "customer")  this.products = await Api.products.search({ query: this.searchQuery });
                else this.products = await AdminApi.products.search({ query: this.searchQuery });
            } catch (error) {
                this.$toast.error("Nie udało się wyszukać produktów");
            }
            this.isLoading = false;
        },


        async add(productId) {
            let prod = null;
            if (this.scope == "customer") prod = await Api.order.addProduct(productId, "presta");
            else prod = await AdminApi.order.addProduct(productId, this.orderId, "presta");
            this.$emit('close', prod);
        },

        cancel() {
            this.$emit('close', null);
        }
    }
}
</script>