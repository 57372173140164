<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Usługa ekspress</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pt-4">
            Usługa ekspres kosztuje 30% wartości Twojego zamówienia.
        </v-card-text>

        <v-card-text>
            <div>Koszt usługi</div>
            <p class="text-h4 mb-0 text--primary">{{ expressPrice | money }}</p>
        </v-card-text>

        <v-card-text class="">
            Ostateczny koszt może ulec zmianie jeśli będziesz modyfikować swoje zamówienie.<br/> Czy na pewno chesz zamówić tą usługę?
        </v-card-text>
        
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="agree">Zamawiam</v-btn>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
export default {
    props: {
        order: Object
    },

    data() {
        return {
            loading: false
        }
    },

    created() {
    },

    computed: {
        expressPrice: function () {
            let products = this.order.prices.products || 0;
            let discounts = this.order.prices.discounts || 0;
            let express = (products - discounts) * 0.3;
            express = Math.round(express * 100) / 100;
            return express;
        }
    },

    methods: {
        agree() {
            this.$emit('close', true);
        },

        cancel() {
            this.$emit('close', false);
        }
    },
}
</script>