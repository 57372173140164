module.exports = {
    STATUSES_MAP: {
        'waiting-for-payment':   { name:'Oczekuje na płatność',   color:'#EF7F1A'},
        'newly-placed':   { name:'Zamówienie przyjęte',           color:'#EF7F1A'},
        'project':        { name:'Wysłano projekt do akceptacji', color:'#3AE63A'},
        'ready-to-print': { name:'Gotowe do druku',               color:'#EB3498'},
        'printing':       { name:'W trakcie druku',               color:'#335BD4', locked: true },
        'printed':        { name:'Wydrukowane',                   color:'#93B196', locked: true },
        'ready-to-take':  { name:'Gotowe do odbioru',             color:'#086E08', locked: true },
        'send':           { name:'Wysłane',                       color:'#7617CF', locked: true },
        'order-taken':    { name:'Odebrano osobiście',            color:'#7617CF', locked: true },
        'refund-resolved':{ name:'Reklamacja rozpatrzona',        color:'#6E0000', locked: true },
        'canceled':       { name:'Anulowane',                     color:'#222211', locked: true },
        'need-explain':   { name:'Do wyjaśnienia',                color:'#E31E24', locked: true }
    }
};