<template>
    <div>
        <v-checkbox 
            class="mt-0 ml-4 pt-0 mb-3"
            v-model="isCode"
            label="Opcja inpost paczkomat za pobraniem"
            hide-details
        ></v-checkbox>
        <div id="easypack-map"></div>
    </div>
</template>
<script>
export default {
    props: {
    },
    components: {
    },
    data() {
        return {
            isCode:false
        }
    },

    created() {
        if (!window.easyPackAsyncInit) {
            window.easyPackAsyncInit = () => {
                window.easyPack.init({
                    mapType: 'osm',
                    searchType: 'osm',
                });
            };
        }

        window.easyPack.mapWidget('easypack-map', (point) => {
            this.$emit("submit", point, this.isCode);
            
        });
    },

    methods: {
    },
}
</script>