import Api from './Api.js';

let pendingPromise = null;

class EmployeeManager {
    constructor() {
        this.employees = [];
        this._isDataValid = true;
    }

    invalidate() {
        this._isDataValid = false;
    }

    getEmployees() {
        if (pendingPromise != null) { return pendingPromise; }

        pendingPromise = new Promise((resolve, reject) => {
            if (this.employees.length && this._isDataValid) {
                setTimeout(() => {
                    pendingPromise = null;
                }, 1);
                resolve(this.employees);
                return;
            }

            Api.user.get()
                .then((res) => {
                    this.employees = res;
                    this._isDataValid = true;
                    resolve(this.employees);
                    pendingPromise = null;
                })
                .catch((reason) => {
                    resolve(this.employees);
                    pendingPromise = null;
                });
        });

        return pendingPromise;
    }

    getById(id) {
        return this.employees.find(emp => emp._id + "" == id + "");
    }

    getName(id) {
        const emp = this.getById(id);
        if (emp) return emp.name;
        else return "Missing";
    }

    getEmail(id) {
        const emp = this.getById(id);
        if (emp) return emp.email;
        else return "Missing";
    }
}

var instance = new EmployeeManager();

export default instance;
