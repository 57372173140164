<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Zmień sposób dostawy</v-toolbar-title>
        </v-toolbar>
       
        <v-card-text class="pa-4">
            <v-row>
                <v-col cols="12" md="6" sm="6" >
                    <v-select dense outlined :items=types label="Przewoźnik" item-text="name" item-value="_id" @change="changePrice" v-model="delivery.typeId"></v-select>
                </v-col>
                <v-col class="mt-2"  cols="12" md="6" sm="6" >
                    <div>Koszt dostawy: <span class="font-weight-medium" v-if="delivery">{{price}} zł</span></div>
                </v-col>
            </v-row>
            <v-divider/>
        </v-card-text>
        <InpostPointsMap v-if="selectedModule == 'inpost'" @submit="inpostPointSend"></InpostPointsMap>
        <div  v-if="selectedModule == 'carrier'">
            <v-card-title class="pt-0">
                <span class="text-subtitle-1">Adres dostawy</span>
            </v-card-title>

            <v-card-text>
                <v-container>
                    <v-form
                        ref="form"
                        v-model="valid"
                        lazy-validation
                    >
                        <v-row>
                            <v-col class="mt-2"  cols="12" md="4" sm="6" >
                                Imię i nazwisko:
                            </v-col>
                            <v-col cols="12" md="8" sm="6" class="black--text pb-0">
                                <v-text-field
                                    v-model="delivery.data.name"
                                    outlined
                                    dense
                                    :rules="emptyRules"
                                ></v-text-field>
                            </v-col>

                            <v-col class="mt-2"  cols="12" md="4" sm="6" >
                                Ulica:
                            </v-col>
                            <v-col cols="12" md="8" sm="6" class="black--text pb-0">
                                <v-text-field
                                    v-model="delivery.data.address"
                                    outlined
                                    dense
                                    :rules="emptyRules"
                                ></v-text-field>
                            </v-col>
                            <v-col class="mt-2"  cols="12" md="4" sm="12" >
                                Kod i miasto:
                            </v-col>
                            <v-col cols="12" md="3" sm="4" class="black--text pb-0">
                                <v-text-field
                                    v-model="delivery.data.postCode"
                                    outlined
                                    dense
                                    :placeholder="delivery.data.country =='PL' ? 'XX-XXX' : ''"
                                    :rules="delivery.data.country =='PL' ? postRules : emptyRules"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12" md="5" sm="8" class="black--text pb-0">
                                <v-text-field
                                    v-model="delivery.data.city"
                                    outlined
                                    dense
                                    :rules="emptyRules"
                                ></v-text-field>
                            </v-col>
                            <v-col class="mt-2"  cols="12" md="4" sm="6" >
                                Kraj:
                            </v-col>
                            <v-col cols="12" md="8" sm="6" class="black--text">
                                <v-select hide-details dense outlined :items=countries label="Kraj" item-text="name" item-value="id" v-model="delivery.data.country"></v-select>
                            </v-col>
                            <v-col class="mt-2"  cols="12" md="4" sm="6" >
                                Telefon:
                            </v-col>
                            <v-col cols="12" md="8" sm="6" class="black--text pb-0">
                                <v-text-field
                                    v-model="delivery.data.phone"
                                    outlined
                                    dense
                                    :rules="phoneRules"
                                ></v-text-field>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-card-text>
        </div>
        <div v-else></div>
        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn v-if="selectedModule != 'inpost' && selectedModule !='pickup'" color="primary darken-1" text @click.native="agree">Zapisz</v-btn>
            <v-btn v-if="selectedModule == 'pickup'" color="primary darken-1" text @click.native="agreePickup">Zapisz</v-btn>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
        
    </v-card>
</template>

<script>
import InpostPointsMap from './InpostPointsMap.vue';
export default {
    props: {
        delveryTypes: Array,
        orderDelivery: Object
    },
    components: {
        InpostPointsMap,
    },
    data() {
        return {
            types: [],
            delivery:{data:{}},
            loading: false,
            price:null,
            countries: [
                {id:"PL", name:"Polska"},
                {id:"DE", name:"Niemcy"},
                {id:"GB", name:"Wielka Brytania"},
                {id:"CZ", name:"Czechy"},
            ],
            valid:true,
            postRules: [
                v => !!v || 'Kod wymagany',
                // v => ((v || "").match(/\d{2}-\d{3}/)) || 'XX-XXX (liczby)',
                v => /\d{2}-\d{3}/.test(v) || 'XX-XXX (liczby)',
            ],
            phoneRules: [
                v => !!v || 'Pole wymagane',
                // v => ((v || "").match(/^[\d\s+]*$/)) || 'Niepoprawny numer',
                v => /^[\d\s+]*$/.test(v) || 'Niepoprawny numer',
            ],
            emptyRules: [
                v => !!v || 'Pole wymagane',
            ],
            selectedModule: "",

        }
    },

    created() {
        this.types = Object.assign(this.types, this.delveryTypes);
        this.delivery = JSON.parse(JSON.stringify(this.orderDelivery));
        if(!this.delivery.data) this.delivery.data = {};
        if(!this.delivery.data.country || this.delivery.data.country.length == 0) this.delivery.data.country = "PL";
        let idx = this.types.map(d => d._id).indexOf(this.delivery.typeId);
        if(idx != -1) {
            this.price = this.types[idx].priceBrutto;
            this.selectedModule = this.types[idx].module;
        }
    },

    methods: {
        agreePickup() {
            this.$emit('close', this.delivery);
        },
        agree() {
            // validacja
            this.valid = this.$refs.form.validate()
            
            if(!this.valid) {
                this.$toast.error("Formularz jest źle wypełniony");
                return
            }
            this.$emit('close', this.delivery);
        },

        cancel() {
            this.$emit('close', null);
        },
        changePrice() {
            let idx = this.types.map(d => d._id).indexOf(this.delivery.typeId);
            if(idx != -1) {
                this.price = this.types[idx].priceBrutto;
                this.selectedModule = this.types[idx].module;
            }
        },
        inpostPointSend(data, isCod) {
            this.delivery.data = {
                pointCode:data.name,
                pointType: data.type[0],
                addressline1:data.address.line1,
                addressline2:data.address.line2,
                isCod: isCod
            }
            this.$emit('close', this.delivery);
        }
    },
}
</script>