<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Dane do faktury</v-toolbar-title>
        </v-toolbar>
        <v-card-text class="pa-4">
            <v-container>
                <v-form
                    ref="form"
                    v-model="valid"
                    lazy-validation
                >
                    <v-row>
                        <v-col class="mt-2"  cols="12" md="4" sm="6" >
                            NIP:
                        </v-col>
                        <v-col cols="12" md="8" sm="6" class="black--text pb-0">
                            <v-text-field
                                v-model="invoice.nip"
                                outlined
                                dense
                                :rules="emptyRules"
                            ></v-text-field>
                        </v-col>

                        <v-col class="mt-2"  cols="12" md="4" sm="6" >
                            Nazwa firmy:
                        </v-col>
                        <v-col cols="12" md="8" sm="6" class="black--text pb-0">
                            <v-text-field
                                v-model="invoice.companyName"
                                outlined
                                dense
                                :rules="emptyRules"
                            ></v-text-field>
                        </v-col>
                        <v-col class="mt-2"  cols="12" md="4" sm="6" >
                            Ulica:
                        </v-col>
                        <v-col cols="12" md="8" sm="6" class="black--text pb-0">
                            <v-text-field
                                v-model="invoice.address"
                                outlined
                                dense
                                :rules="emptyRules"
                            ></v-text-field>
                        </v-col>
                        <v-col class="mt-2"  cols="12" md="4" sm="12" >
                            Kod i miasto:
                        </v-col>
                        <v-col cols="12" md="3" sm="4" class="black--text pb-0">
                            <v-text-field
                                v-model="invoice.postCode"
                                outlined
                                dense
                                placeholder="XX-XXX"
                                :rules="postRules"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" md="5" sm="8" class="black--text pb-0">
                            <v-text-field
                                v-model="invoice.city"
                                outlined
                                dense
                                :rules="emptyRules"
                            ></v-text-field>
                        </v-col>
                            
                            
                    </v-row>
                </v-form>
            </v-container>
        </v-card-text>

        <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary darken-1" text @click.native="agree">Zapisz</v-btn>
            <v-btn color="grey" text @click.native="cancel">Anuluj</v-btn>
        </v-card-actions>
        
    </v-card>
</template>

<script>
export default {
    props: {
        invoiceData: Object,
    },
    components: {
    },
    data() {
        return {
            invoice:{},
            valid:true,
            postRules: [
                v => !!v || 'Kod wymagany',
                v => (v.match(/\d{2}-\d{3}/)) || 'XX-XXX (liczby)',
            ],
            phoneRules: [
                v => (v.match(/^[\d\s+]*$/)) || 'Niepoprawny numer',
            ],
            emptyRules: [
                v => !!v || 'Pole wymagane',
            ],
        }
    },

    created() {
        this.invoice = Object.assign(this.invoice, this.invoiceData);
    },

    methods: {
        agree() {
            // validacja
            this.$refs.form.validate()
            if(!this.valid) {
                this.$toast.error("Formularz jest źle wypełniony");
                return
            }
            this.$emit('close', this.invoice);
        },

        cancel() {
            this.$emit('close', null);
        },
        
        
    },
}
</script>