<template>
    <v-card>
        <v-toolbar dense color="primary" flat>
            <v-toolbar-title class="white--text">Historia płatności</v-toolbar-title>
        </v-toolbar>
        
        <v-card-text class="pa-0">
            <v-data-table
                :headers="headers"
                :items="payments"
                :hide-default-footer="true"
                class="elevation-1"
            >
                <template v-slot:item.createdAt="{ item }">
                    <span >{{item.createdAt  | moment("DD/MM/YY HH:mm")}}</span>
                </template>
                <template v-slot:item.value="{ item }">
                    <span >{{item.value}}zł</span>
                </template>
                <template v-slot:item.createdBy="{ item }">
                    <span class="text-caption grey--text" v-if="item.createdBy == 'user'">(dodał: Pracownik)</span>
                    <span class="text-caption grey--text" v-else>(dodał: Użytkownik)</span>
                </template>
                <template v-slot:item.status="{ item }">
                    <v-chip
                        color="blue"
                        class="white--text"
                        x-small
                    >{{item.status}}</v-chip>
                </template>
            </v-data-table>
            <v-divider/>
        </v-card-text>
        <v-card-actions class="pt-4">
            <v-spacer></v-spacer>
            <!-- <v-btn color="primary darken-1" text @click.native="agree">Zapisz</v-btn> -->
            <v-btn color="grey" text @click.native="cancel">Cofnij</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
export default {
    props: {
        payments: Array
    },
    data: () => ({
        headers: [
            { text: 'Kwota', value: 'value',  sortable: false  },
            { text: 'Data', value: 'createdAt', sortable: false, },
            { text: 'Status', value: 'status',  sortable: false  },
            { text: 'Metoda', value: 'module',  sortable: false  },
            { text: '', value: 'createdBy',  sortable: false  },
        ],
    }),
    created() {
    },
    methods: {
        agree() {
            let value = parseFloat(this.voucher.value);
            this.voucher.value = value;
            this.$emit('close',this.voucher);
            this.dialog = false;
        },
        cancel() {
            this.$emit('close',false)
            this.dialog = false;
        },
        
    }
}
</script>